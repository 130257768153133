import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({data}) => {
  console.log(data)
  return (
    <Layout>
      <div>
        <h3>My site's files.</h3>
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>base</th>
              <th>accessTime</th>
              <th>modifiedTime</th>
            </tr>
          </thead>
          <tbody>
            {data.allFile.edges.map(({node}, index) => (
              <tr key={index}>
                <td>{node.id}</td>
                <td>{node.base}</td>
                <td>{node.accessTime}</td>
                <td>{node.modifiedTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allFile {
      edges {
        node {
          id
          base
          accessTime
          modifiedTime
        }
      }
    }
  }
`